<template>
  <v-container
    v-if="this.$isEnabledProperty(this.$COMPRAR_ELEMENTOS_ADUANA)"
    id="crud"
    fluid
    tag="section"
  >
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>Administración de {{ title }}</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
function title() {
  return "Elementos Aduana";
}

export default {
  data: (vm) => ({
    snackbar: false,
    text: "Registro Insertado",

    color: "success",
    timeout: 4000,
    title: title(),
  }),

  created() {},

  methods: {},
  mounted() {
    console.log("Componente " + this.baseUrl + " creado");
  },
};
</script>
